import {Link} from "react-router-dom"
import QuantityBtn from "./QuantityBtn"
import { useState, useEffect } from "react"

export default function ProductList() {

    let [productList, setProductList] = useState([])

    useEffect(()=>{
        fetch('https://demo-movie-db.onrender.com/film/list')
            .then(response => response.json())
            .then(data => setProductList(data))
    },[])

    return (
        <>
            {/* {showProduct && <button onClick = {()=>{setShowProduct(false)}}>Hide</button>}
            {!showProduct && <button onClick = {()=>{setShowProduct(true)}}>Show</button>} */}

            { productList.length === 0 ?
            <div className="loadingScreen">Loading...</div>
            : 
                <div className="container">
                    {
                        /*showProduct && */productList && productList.map(product =>(
                                <div className="containerItem" key={product.id}>
                                    <Link to={'/product/'+product.id}>
                                    <img src={process.env.PUBLIC_URL+product.poster} alt='product.name' width="180" height="270"/> <br/>
                                    </Link>
                                    <div className="productName">
                                        Title: {product.title}<br/>
                                        Year: {product.year}<br/>
                                        {product.genre}<br/>
                                        ${product.price}<br/>
                                    </div>
                                    <QuantityBtn productInfo={product}/>
                                </div>
                            )
                        )
                    }
                </div> 
            }
        </>
    )
}

    // let [productList, setProductList] = useState([]) //useState回來是一個Array
    //let [input, setInput] = useState([''])

//     let ProductList = [
//         {"id": 1, "name": "Man of Steel", "Year":"2013","Genre":"Action, Adventure, Sci-Fi",
//         "Poster":"https://m.media-amazon.com/images/M/MV5BMTk5ODk1NDkxMF5BMl5BanBnXkFtZTcwNTA5OTY0OQ@@._V1_SX300.jpg","price": 100},
//         {"id": 2, "name": "Wonder Woman", "Year":"2017","Genre":"Action, Adventure, Fantasy",
//         "Poster":"https://m.media-amazon.com/images/M/MV5BMTYzODQzYjQtNTczNC00MzZhLTg1ZWYtZDUxYmQ3ZTY4NzA1XkEyXkFqcGdeQXVyODE5NzE3OTE@._V1_SX300.jpg","price": 100},
//         {"id": 3, "name": "Blade Runner 2049", "Year":"2017","Genre":"Action, Drama, Mystery",
//         "Poster":"https://m.media-amazon.com/images/M/MV5BNzA1Njg4NzYxOV5BMl5BanBnXkFtZTgwODk5NjU3MzI@._V1_SX300.jpg","price": 100}
//    ]
    

    // useEffect(()=>{
    //     //1: 無第二個參數 : component每次render都會觸發
    //     //2: dependency array是空array時: 只會在第一次render時會觸發
    //     //3: dependency array是有變數時 : 第一次網頁render時 + 指定的變數改變 會觸發
    // fetch('http://hoyinleung.github.io/demoapi/react-basic-product.json')   
    // .then(req => req.json())
    // .then(data => setProductList(data))
    // console.log(productList)
    // },[])

    // 節省<div> 它渲染組件更快，使用的內存更少。如果你要向組件的元素添加key，則必須使用div。
    // <> </> 為React Fragment的簡寫
    // <React.Fragment> </React.Fragment> 為全寫

    // useEffect(()=>{ //練習何時觸發 多數用在驗証用戶資料時
    //     if(input.length>4)
    //     console.log('hi')
    //     else
    //     console.log('not hi')
    // },[input]);
    // <input type="text" onChange={e=>setInput(e.target.value)} />
    
    // const [product, setProduct] = useState('電影')
    // const handleClick = () => {
    //     setProduct('react')
    //     console.log(product)
    // } 
    //   //更新變數 變字 按鈕將電影2字變做react

    // const [showProduct, setShowProduct] = useState(true) //入頁面Default show
    // Show Hide制 https://www.youtube.com/watch?v=mdlm9dNWbog
