import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function Register() {

  const [showAblert, setShowAblert] = useState(true);

  useEffect(() => {
    if(showAblert) {
      setShowAblert(false)
      alert(`It is only for test purposes, please do not fill in real information!`)
    }
  }, [showAblert])

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [responseDescription, setResponseDescription] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();

    const user = {
      username: username,
      password: password,
      email: email
    }

    console.log(user);

    axios.post('https://demo-movie-db.onrender.com/user/apply', user)
      .then(res => {
        console.log(res.data);
        setResponseDescription(res.data.description);
      })
      .catch(err => {
        console.log(err);
        setResponseDescription(err.response.data.description);
      })

    setUsername("");
    setPassword("");
    setEmail("");
  }

  return (
    <div className='login-register'>
      <h3>Create New User</h3>
      <form onSubmit={onSubmit}>
        <div className="form-group"> 
          <label>Username: </label>
          <input type="text"
              required
              className="form-control"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
          />
          <label>Password: </label>
          <input type="password"
              required
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
          />
          <label>Email: </label>
          <input type="text"
              required
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group" style={{ textAlign: 'center'}}>
          <input type="submit" value="Create User" className="submit" />
        </div>
      </form>
      {responseDescription && // 顯示回傳結果
        <div>
          <p style={{color:'red', background:'#d38a7d', opacity:'0.8', textAlign:'center'}}>{responseDescription}</p>
        </div>
      }
    </div>
  )
}
