import React from 'react';
import { useState } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { CartContext } from './CartContext';
import { RequireAuth } from 'react-auth-kit';
import ProductList from './ProductList';
import ProductDetail from './ProductDetail';
import CheckOut from './CheckOut';
import MovieHub from './MovieHub';
import Contact from './Contact';
import Chatbot from './Chatbot';
import { Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Login';
import Register from './Register';
import TestLogin from './TestLogin'

function App() {

  const [cartItems, setCartItems] = useState([]);

  return (
    <BrowserRouter>
      <CartContext.Provider value={{cartItems, setCartItems}}>
        <Navbar bg="dark" expand="lg">
        <Navbar.Brand as={Link} to="/" style={{color:'white'}}>Movie Hub</Navbar.Brand >
        <Nav.Link as={Link} to="/shop" style={{padding:'8px', color:'white'}}>Shop</Nav.Link>
        <Nav.Link as={Link} to="/checkout" style={{padding:'8px', color:'white'}}>Check-Out</Nav.Link>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link as={Link} to="/contact" style={{color:'white'}}>Contact</Nav.Link>
              <Nav.Link as={Link} to="/chatbot" style={{color:'white'}}>Chatbot</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          {/* Open a new collapse to fix the problem  */}
          <Navbar.Collapse id="basic-navbar-nav" style={{justifyContent:'end', padding:'0px 20px'}}>
            <Nav.Link as={Link} to="/login" style={{padding:'8px', color:'white'}}>Login</Nav.Link>
            <Nav.Link as={Link} to="/register" style={{padding:'8px', color:'white'}}>Register</Nav.Link>

            {/* cancel this test*/}
            {/* <Nav.Link as={Link} to="/testlogin" style={{padding:'8px', color:'white'}}>Test Login</Nav.Link>*/}

          </Navbar.Collapse>
        </Navbar>

        <Routes>
          <Route path="/" element={<MovieHub/>} />
          <Route path="/shop" element={<ProductList/>} />
          <Route path="/checkout" element={<CheckOut/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/chatbot" element={<Chatbot/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/register" element={<Register/>} />

          {/* need login in path*/}
          <Route path="/testlogin" element={<RequireAuth loginPath="/login"><TestLogin/></RequireAuth>}/>


          {/* for product detail, make a new path /1 /2 /3... with product*/}
          <Route path="/product" element={<ProductDetail/>}>
            <Route path=":id" element={<ProductDetail/>} />
          </Route>

          <Route path="*" element={<p>404 not found</p>}/>
        </Routes>
      </CartContext.Provider>
    </BrowserRouter>
  );
}

export default App;
