import { useSignOut } from "react-auth-kit"
import { useNavigate } from "react-router-dom";

export default function TestLogin() {
    const signOut = useSignOut();
    const navigate = useNavigate();

    const logout = () => {
        signOut();
        localStorage.removeItem('signInToken')
        navigate("/shop")
    }

    return (
        <div className="testLogin">
            <p style={{color:'white'}}>This is a test login page, if you are here, you are already logged in!</p>
            <input type="submit" value="Logout" className="submit" onClick={logout}/>
        </div>
    )    
}
