import {useContext, useState} from "react"
import { CartContext } from "./CartContext"

export default function QuantityBtn({productInfo}) { ////productInfo代表QuantityBtn制名

    const {cartItems, setCartItems}= useContext(CartContext)

    let productIndexInCart = cartItems.findIndex((element)=>{
        return element.id === productInfo.id
    })
    //findIndex
    //如果在購物車內找到產品 => 返回索引位 即(productIndexInCart)
    //該物品沒有在購物車找到 => 返回 -1 即(productIndexInCart = -1)

    let [numInCart, setNumInCart] = useState(
        (productIndexInCart===-1) ? 0 : cartItems[productIndexInCart].quantity
    )  

        const handleAdd = ()=>{

            if(productIndexInCart===-1) {
                setCartItems(
                    [{
                        ////購物車本身沒有，在cartItems array中加個新element (object)
                        id: productInfo.id,
                        title:productInfo.title,
                        year:productInfo.year,
                        genre:productInfo.genre,
                        poster:productInfo.poster,
                        price:productInfo.price,
                        quantity:1
                    },
                    ...cartItems]
                    )
                } 
                else 
                {
                //購物車有該產品，只加個quantity
                let newCartArray = [...cartItems]
                newCartArray[productIndexInCart].quantity++
                setCartItems(newCartArray)
            }
            setNumInCart(numInCart+1)
        }

        const handleSubtract = ()=>{

        if(cartItems[productIndexInCart].quantity===1)
        {
            //購在物車中只剩一件的話，remove object
            let newCartArray = [...cartItems]
            newCartArray.splice(productIndexInCart,1)
            setCartItems(newCartArray)
        }
        else
        {
            //只減個quantity
            let newCartArray = [...cartItems]
            newCartArray[productIndexInCart].quantity--
            setCartItems(newCartArray)
        }
        setNumInCart(numInCart-1)
    }

    return (
        <div>
            {
                (numInCart === 0) ? <div className="addToCartBtn" onClick={handleAdd}>Add to cart</div> :
                <div>
                    <span className="input-group-btn">
                    <button className="subtractBtn" onClick={handleSubtract}>-</button>
                    {numInCart}
                    <button className="addBtn" onClick={handleAdd}>+</button>
                    </span>
                </div>            
            }
        </div>
    )
}