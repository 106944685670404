import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from 'react-auth-kit';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider
      authType={"cookie"}
      authName={"auth"}
      cookieDomain={window.location.hostname}
      cookieSecure={false}
      //cookieMaxAge={60 * 60 * 24 * 7}   set cookie time to max
    >
    <App />
    </AuthProvider>
  </React.StrictMode>
);