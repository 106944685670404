import {useParams, Link} from "react-router-dom"
import QuantityBtn from "./QuantityBtn"
import { useState,useEffect } from "react"

export default function ProductDetail() {

    let params = useParams()

    let [productDetail,setProductDetail] = useState(null)

    useEffect(()=>{
        fetch('https://demo-movie-db.onrender.com/film/list')
        .then(response => response.json())
        .then(data => {
            let productInfo = data.find((element)=>{
                return element.id === parseInt(params.id)
            })
            setProductDetail(productInfo)
        })
},[params.id])
    

    return (
        <>
            <div className="ProductDetail">
                 {
                    productDetail && //如fetch 回傳結果才顯示
                    <div>
                        <img src={productDetail.poster} alt='product.name' width="180"/>
                        <p>Title : {productDetail.title}</p>
                        <p>Price : ${productDetail.price}</p>
                        <QuantityBtn productInfo={productDetail}/><br/>
                        <Link to='/shop' className="shopNow">Shop now</Link>
                         </div>
                         }
            </div>
        </>
    )
}