export default function MovieHub() {

    return (
        <div className="MovieHub">
            <p style={{color:'white'}}>This is a test web service to obtain and simulate buying movies, chat with ChatGPT
            all content and images on the site are contributed and maintained by <br/>
            <a href='https://www.omdbapi.com/' style={{textDecorationLine:'none', color:'lightgrey'}}>OMDb API, </a>
            <a href='https://openai.com/' style={{textDecorationLine:'none', color:'lightgrey'}}>Open AI</a>
            <br/>
            If you find this service useful, please consider making a one-time donation or become a patron.
            </p>
        </div>
    )
    
}