import React, { useState, useEffect }  from 'react';
import axios from 'axios';
import { useSignIn , useSignOut} from 'react-auth-kit';
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [responseDescription, setResponseDescription] = useState("");
  const [loggedIn, setLoggedIn] = useState(false)
  const signIn = useSignIn();
  const signOut = useSignOut();
  const navigate = useNavigate();

  
  useEffect(() => {
    const storedToken = localStorage.getItem('signInToken')
    if (storedToken){
        setLoggedIn(true);
    }
  }, [])   

  const onSubmit = (e) => {
    e.preventDefault();

    const user = {
      username: username,
      password: password,
    }

    console.log(user);

    axios.post('https://demo-movie-db.onrender.com/user/auth', user)
      .then(res => {
        console.log(res.data);
        setResponseDescription(res.data.description);
        signIn({
          token: res.data.token,
          expiresIn: 180,
          tokenType: "Bearer",
          authState: { username: username },
        });
        if (res.data.description === "Login successfully") {
          setLoggedIn(true)
          navigate("/testlogin");
          //navigate("/login");
          localStorage.setItem('signInToken', res.data.token)
        }
      })
      .catch(err => {
        console.log(err);
        setResponseDescription(err.response.data.description);
      })
  }

  const logout = () => {
      signOut();
      setLoggedIn(false)
      localStorage.removeItem('signInToken')
      navigate("/shop")
  }

  return (
    <div className='login-register'>
      {!loggedIn && (
        <>
          <h3>Login</h3>
          <form onSubmit={onSubmit}>
            <div className="form-group"> 
              <label>Username: </label>
              <input type="text"
                  required
                  placeholder="Try: guest"
                  className="form-control"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  />
              <label>Password: </label>
              <input type="password"
                  required
                  placeholder="Try: guest"
                  className="form-control"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  />
            </div>
            <div className="form-group" style={{ textAlign: 'center'}}>
              <input type="submit" value="Login" className="submit" />
            </div>
          </form>
          {responseDescription && // 顯示回傳結果
            <div>
              <p style={{color:'red', background:'#d38a7d', opacity:'0.8', textAlign:'center'}}>{responseDescription}</p>
            </div>
          }
        </>
        )}
        { loggedIn && (
          <div className='afterLogin'>
            <p>Login success!</p>
            <input type="submit" value="Logout" className="afterLoginSubmit" onClick={logout}/>
          </div>
          )
      }
    </div>
  )
}
