import React, {useState } from 'react'
import axios from 'axios';

export default function Contact() {
    const [name,setName]=useState('')
    const [email,setEmail]=useState('')
    const [message,setMessage]=useState('')
    const [error,setError]=useState(false)

    const sendMesssage = {
        name: name,
        email: email,
        message: message
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        if(name.length===0||email.length===0||message.length===0)
        {
            setError(true)
        }
        if(name&&email&&message)
        {
        axios.post('https://demo-movie-db.onrender.com/user/sendmessage', sendMesssage)
        .then(res => {
            console.log(res.data);
        })
        .catch(err => {
            console.log(err);
        })
        }

        if(!name) {
            alert("Name can't be Empty")
        } else if(!email) {
            alert("Email can't be Empty")
        } else if (!message) {
            alert("Message can't be Empty")
        } else {
            alert("Thankyou for your submit")
            setName('')
            setEmail('')
            setMessage('')
        }
    }

    return(
        <div className="contact">
            <form onSubmit={handleSubmit} className="form">
                <input type="text" placeholder=" Name" id="name"  value={name} onChange={e=>setName(e.target.value)} />                    
                <input type="email" placeholder=" Email" id="email " value={email}  onChange={e=>setEmail(e.target.value)} />
                <textarea className="Message" type="text" placeholder="Message" id="message"  value={message} onChange={e=>setMessage(e.target.value)} />                    
                
                {error&&name.length<=0? <label className='error'>Name can't be Empty</label>:""}
                {error&&email.length<=0? <label className='error'>Email can't be Empty</label>:""}
                {error&&message.length<=0? <label className='error'>Message can't be Empty</label>:""}

                <input onClick={() => handleSubmit} className="contactSubmit" type="submit" value="SUBMIT" />                   
            </form>
            
        </div>
    ); 
}
