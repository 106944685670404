import {Link} from "react-router-dom"
import QuantityBtn from "./QuantityBtn"
import { useContext } from "react"
import { CartContext } from "./CartContext"

export default function CheckOut() {

    let { cartItems, setCartItems } = useContext(CartContext)
    let cartEmpyt = cartItems.length <= 0 ? true: false

    let grandTotal = cartItems.reduce((total, product)=>{
        return total += product.price*product.quantity
    },0)
    const freeShippingPrice = 500

    const cleanStore = () => {
        setCartItems([])
        alert('Payment successful')
    }

    return (
        <div>
            {
                cartEmpyt &&
                <div className="nothingInCart">
                    Your shopping cart is empty. Shop Your Favorites Now<br/><br/>
                    <Link className="shopNow" to="/shop">Shop now</Link>
                </div>
            }

            {
                !cartEmpyt &&
                <div className="container">
                    <div className="cartSection">
                        <table className="checkoutTable">
                            <tbody>
                                {
                            cartItems.map(product =>(
                                <tr key={product.id}>
                                    <td>
                                    <img src={process.env.PUBLIC_URL+product.poster} alt={product.name} width="160"/> 
                                    </td>
                                    <td className="moblieCheckOut">
                                        <td>
                                            <p>{product.title} </p>
                                            <p>${product.price} </p>
                                            <p>Qty {product.quantity}</p>
                                        </td>
                                        <td width="200px">
                                            <QuantityBtn productInfo={product}/>
                                        </td>
                                        <td>
                                            <div className="productSubTotal">
                                                ${product.price*product.quantity}
                                            </div>
                                        </td>
                                    </td>
                                </tr>
                                ))}   
                            </tbody>
                        </table>
                    </div>

                    <div className="checkoutSection">
                        <div>Total Price</div>
                        <div className="grandTotal">${grandTotal}</div>

                        {
                            grandTotal >= freeShippingPrice ?
                            <div className="freeShipping">✔️Free Shipping available</div> :
                            <div className="noShipping">Free Shipping is available over ${freeShippingPrice}!<br/>
                            Now need ${freeShippingPrice-grandTotal} more!</div>
                        }
                        <br/>
                        <button onClick={cleanStore}>PAY NOW</button>
                    </div>
                </div>
            }
        </div>
    )
}
